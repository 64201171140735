/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages


        var windowWidth = $(window).width();

        var menuBreakpoint = 1024;

        $('.search-mobile').click(function(){
          $('html, body').animate({
            scrollTop: $("#footer-search").offset().top
          }, 500);
        });

        /*
         Font Size CSS fixes
         */
        var activeClass = 'resize-active';

        function reset_feat_img(){
          if( $('.ct-font-resizer-plus').hasClass(activeClass) ){
            $('.feat-image').css('margin-top', '112px');
          }

          if( $('.ct-font-resizer-minus').hasClass(activeClass) ){
            $('.feat-image').css('margin-top', '103px');
          }
        }

        /*
        Font resizer limits
         */

        var sizers = $('.fontResizer').find('a');

        $('.fontResizer').find('a').click(function( e ){

          $('.fontResizer').find('a').removeClass(activeClass);

          if( $(this).hasClass( activeClass ) ){
            $(this).off();
          }else{
            if( $(this).hasClass('ct-font-resizer-plus') || $(this).hasClass('ct-font-resizer-minus') ){
              $(this).addClass(activeClass);
            }
          }

          if ( windowWidth > menuBreakpoint ){
            reset_feat_img();
          }


        });

        if ( windowWidth > menuBreakpoint ){
          reset_feat_img();
        }

        /*
         Window Resize resets
         */
        $(window).resize(function() {

          if( windowWidth !== $(window).width() ){

            windowWidth = $(window).width();

            if( windowWidth  > menuBreakpoint ){
              $('.nav-primary').show();
            }else{
              $('.nav-primary').hide();
            }
          }
        });

        /*
        Nav Items Full Width
         */
        var navItems = $('.banner .nav > .menu-item');

        var liWidth = 100 / navItems.length;

        /*
        Dropdown menus
         */
        function oac_dropdown_hover(){

          if( windowWidth + 15 > menuBreakpoint ){

            $('.banner .menu-item-has-children').children('a').mouseover( function( e ){


              //remove others
              $('.menu-item-has-children a').removeClass('blue-border');
              $('.banner .sub-menu').fadeOut(10);

              //add current to main item
              if( $(this).closest('ul').hasClass('nav') && !$(this).hasClass('blue-border-static')  ){
                $(this).first().addClass('blue-border');
                $(this).closest('li').find('.sub-menu').fadeIn(10);
              }

            });

            $('.banner').find('.sub-menu').mouseover(function(){
              $(this).show();
              $(this).closest('.menu-item-has-children').find('a').first().addClass('blue-border-static');
            });

            $('.banner').find('.sub-menu').mouseout(function(){
              $(this).hide();
              $(this).closest('.menu-item-has-children').find('a').first().removeClass('blue-border');
              $(this).closest('.menu-item-has-children').find('a').first().removeClass('blue-border-static');
            });
          }
        }

        oac_dropdown_hover();

        /*
        Mobile Menu
         */
        $('.toggle-button').click(function(){

          if( $(this).hasClass('visible') ){
            $('.nav-primary').hide();
            $(this).removeClass('visible');
            $('.closer').hide();
            $('.opener').show();
          }else{
            $(this).addClass('visible');
            $('.nav-primary').show();
            $('.closer').show();
            $('.opener').hide();
          }
        });

        /*
        Accordion Toggle
         */
        $('.accordion-toggle').hide();
        $('.partner-toggle').click( function(){
          if( !$(this).hasClass('open') ){
            $(this).addClass('open');
            $(this).find('.plus').hide();
            $(this).find('.minus').show();
            $(this).closest('.partner-item').find('.accordion-toggle').show();
          }else{
            $(this).removeClass('open');
            $(this).find('.plus').show();
            $(this).find('.minus').hide();
            $(this).closest('.partner-item').find('.accordion-toggle').hide();
          }
        });

        /*
        Event Table Sorter
         */
        function sortTable( column ) {

          var table, rows, switching, i, x, y, shouldSwitch;
          table = document.getElementById("event-table");
          switching = true;

          /*Make a loop that will continue until no switching has been done:*/
          while (switching) {
            //start by saying: no switching is done:
            switching = false;
            rows = table.getElementsByClassName("primary-fields");
            console.log( rows );

            /*Loop through table rows*/
            for (i = 0; i < (rows.length); i++) {

              shouldSwitch = false;

              /*Get the two elements you want to compare, one from current row and one from the next:*/
              if( rows[i+1] ){
                x = rows[i].getElementsByTagName("TD")[column];
                y = rows[i+1].getElementsByTagName("TD")[column];
              }

              //date sort
              if( column === 0 ){
                if (x.dataset.eventtime > y.dataset.eventtime ) {
                  shouldSwitch= true;
                  break;
                }
              }

              //city sort
              if( column === 2 ) {
                if (x.dataset.city > y.dataset.city) {
                  shouldSwitch= true;
                  break;
                }
              }

            }
            if (shouldSwitch) {
              /*If a switch has been marked, make the switch and mark that a switch has been done:*/
              rows[i].parentNode.insertBefore( rows[i + 1], rows[i] );
              switching = true;
            }
          }
        }

        var eventEl = $('#event-sorter');

        if( eventEl.length ){
          sortTable(0);
        }

        eventEl.change( function(){

          var selected = $(this).val();

          if( selected === 'date' ){
            sortTable( 0 );
          }

          if( selected === 'location' ){
            sortTable( 2 );
          }

        });


        /*
        Google Analytics Custom Events
         */
        $('#seniorsFairDocument').click(function(){
          console.log('senior fairs click');
          ga('send', {
            hitType: 'event',
            eventCategory: 'Seniors Fairs',
            eventAction: 'click',
            eventLabel: 'HANDBOOK OACAO Seniors Information Fairs Sept. 2018 - March 2019 Final'
          });
        });

        $('.programs-guide').click(function() {
          console.log('programs guide click');
          ga('send', {
            hitType: 'event',
            eventCategory: 'Seniors Fairs',
            eventAction: 'click',
            eventLabel: 'A Guide to Programs and Services for Seniors'
          });
        });


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
